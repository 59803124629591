import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {tap} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {AuthenticationClient} from "../swagger/generated/swagger-client";

@Injectable()
export class ClientAuthenticationRejectGuard {
  constructor(private router: Router, private authService: AuthService, private authenticationClient: AuthenticationClient) {
  }

  canActivate(): Observable<boolean> {
    /* Check if there is a token in the localstorage */
    let key: string = this.authService.clientToken;
    if (key == null) {
      /* No token is found in the localstorage */
      return of(true);
    }

    return this.authenticationClient.validateToken(key)
      .pipe(
        map((token) => {
          if (token == null) {
            /* Token found in localstorage is no longer valid */
            return true;
          } else {
            /* Token found in localstorage is valid */
            return false;
          }
        }),
        tap((valid) => {
          if (!valid) {
            /* No errors, token is ok */
            this.router.navigate(['client']);
          }
        })
      );
  }
}
